import type { StateProps } from 'browser/store'
import type { Building, Floor, Flat } from 'browser/types'
import slugify from 'utils/slugify'

export type Getters = {
  getFlatsByFloor(floor: Flat['floor']): Flat[]
  getFlatsByBuilding(building: Building['name']): Flat[]
  getFlatsByBuildingAndFloor(
    building: Building['name'],
    floor: Flat['floor']
  ): Flat[]
  getNotSoldFlats(): Flat[]
  getFilteredByStatus(array: Flat[], status: Flat['status']): Flat[]
  getBuilding(building: string): Building | null
  getFloor(building: Building, floor: Flat['floor']): Floor | null
}

const generateGetters = (state: StateProps): Getters => ({
  getFlatsByFloor(floor: Flat['floor']) {
    const { allFlats } = state
    return allFlats.filter((flat) => flat.floor === floor)
  },

  getFlatsByBuilding(building: Building['name']) {
    const { allFlats } = state

    return allFlats.filter(
      (flat) => slugify(flat.building) === slugify(building)
    )
  },

  getFlatsByBuildingAndFloor(building: Building['name'], floor: Flat['floor']) {
    const { investment } = state

    if (investment && investment.multiBuilding) {
      return this.getFlatsByBuilding(building).filter(
        (flat) => flat.floor === floor
      )
    }
    return this.getFlatsByFloor(floor)
  },

  getNotSoldFlats() {
    const { flats } = state
    return flats.filter((flat) => flat.status !== 'sold')
  },

  getFilteredByStatus(array: Flat[], status: Flat['status']) {
    return array.filter((flat) => flat.status === status)
  },

  getBuilding(building: string) {
    const { investment } = state
    if (investment)
      return investment.buildings.get(slugify(`${building}`)) || null

    return null
  },

  getFloor(building: Building, floor: Flat['floor']) {
    if (building) {
      return building.floors.list.find((el) => el.level === floor) || null
    }
    return null
  },
})

export default generateGetters

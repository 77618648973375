import slugify from 'utils/slugify'

import type { Building } from 'browser/types'

type InvestmentType = {
  investmentName: string
  buildings: Map<string, Building>
  buildingsMap?: React.FC<React.SVGProps<SVGSVGElement>> | null
  buildingsBackground?: string
}

class Investment {
  investmentName: InvestmentType['investmentName']

  buildings: InvestmentType['buildings']

  buildingsMap?: InvestmentType['buildingsMap']

  buildingsBackground?: InvestmentType['buildingsBackground']

  url: string

  multiBuilding: boolean

  constructor(data: InvestmentType) {
    this.investmentName = data.investmentName // Name of the investment
    this.buildings = new Map(data.buildings) // Map of Building items
    this.buildingsMap = data.buildingsMap // SVG map of buildings
    this.buildingsBackground = data.buildingsBackground // Background of buildings
    this.url = slugify(this.investmentName) // route url
    this.multiBuilding = Boolean(this.buildings.size > 1)
  }
}

export default Investment

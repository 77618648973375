type FloorType = {
  map: React.FC<React.SVGProps<SVGSVGElement>> | null
  backgroundImage: string
  level: string
}

class Floor {
  map: FloorType['map']

  backgroundImage: FloorType['backgroundImage']

  level: FloorType['level']

  constructor(data: FloorType) {
    this.map = data.map // Svg map
    this.backgroundImage = data.backgroundImage // Url to the background-image.
    this.level = String(data.level)
  }
}

export default Floor

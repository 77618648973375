import { useState, useEffect } from 'react'

import {
  getInitialRangeFilter,
  getInitialSelectFilter,
} from 'utils/getInitialFilter'

import type {
  FiltersState,
  InvestmentFilters,
  SelectFilter,
  RangeFilter,
  Range,
  FilterField,
  FilterItemType,
  FilterItems,
} from 'types/filters'
import type { Flat } from 'types/flat'

type FilterParams<T extends FilterItemType> = {
  items: FilterItems<T>
  includedFilters?: FilterField[]
}

type FilterData<T extends FilterItemType> = [
  FilterItems<T>,
  {
    applyFilters: () => void
    clearFilters: () => void
    setFilters: (filters: FiltersState) => void
    filters: FiltersState
    fields: FilterField[]
  }
]

const DEFAULT_FILTERS: FilterField[] = [
  { key: 'location', type: 'select' },
  { key: 'type', type: 'select' },
  { key: 'rooms', type: 'select' },
  { key: 'floor', type: 'select' },
  { key: 'area', type: 'range' },
]

const useFilters = <T extends FilterItemType>(
  type: T,
  params: FilterParams<T>
): FilterData<T> => {
  const { includedFilters, items } = params

  const fields = includedFilters || DEFAULT_FILTERS

  const getDefaultFilters = (reset?: boolean) => {
    return fields.reduce((prev, filter) => {
      const getFilterFn =
        filter.type === 'range' ? getInitialRangeFilter : getInitialSelectFilter

      return {
        ...prev,
        [filter.key]: getFilterFn(type, {
          key: filter.key,
          storageKey: `FILTER_${filter.key.toUpperCase()}`,
          reset,
          items,
        }),
      }
    }, {}) as FiltersState
  }

  const [filteredItems, setFilteredItems] = useState(items)
  const [filters, setFilters] = useState(getDefaultFilters())

  const applyDefaultFilters = (reset?: boolean) => {
    const defaultFilters = getDefaultFilters(reset)

    setFilters(defaultFilters)
  }

  const validateSelectFilter = (
    key: FilterField['key'],
    value: string[] | string
  ) => {
    const filter = filters[key] as SelectFilter | undefined
    if (!filter) return true

    const isChecked = filter.active.length > 0

    if (Array.isArray(value))
      return !isChecked || filter.active.some((el) => value.includes(el))

    return !isChecked || filter.active.includes(value)
  }

  const validateRangeFilter = (
    key: FilterField['key'],
    value: Range | number
  ) => {
    const filter = filters[key] as RangeFilter | undefined
    if (!filter) return true

    if (typeof value === 'number')
      return value >= filter.active.min && value <= filter.active.max

    return !(filter.active.min > value.max || value.min > filter.active.max)
  }

  const validateStatus = (item: InvestmentFilters | Flat) => {
    if (type === 'investment') {
      return true
    }
    if (item.investment === 'ART Malta') return true

    return (item as Flat).status !== 'sold'
  }

  const validateItem = (item: Flat) => {
    return (
      validateStatus(item) &&
      validateSelectFilter('location', item.location) &&
      validateSelectFilter('type', item.type) &&
      validateSelectFilter('rooms', item.rooms) &&
      validateSelectFilter('floor', item.floor) &&
      validateRangeFilter('area', item.area) &&
      validateSelectFilter('garden', String(item.garden))
    )
  }

  const applyFilters = () => {
    const filtered = (items as Array<Flat>).filter((item) =>
      validateItem(item)
    ) as FilterItems<T>

    setFilteredItems(filtered)

    fields.forEach(({ key }) => {
      const filter = filters[key]
      if (filter) {
        sessionStorage.setItem(
          `FILTER_${key.toUpperCase()}`,
          JSON.stringify(filter.active)
        )
      }
    })
  }

  const clearFilters = () => {
    applyDefaultFilters(true)
  }

  useEffect(() => {
    applyDefaultFilters()
    applyFilters()
  }, [])

  return [
    filteredItems,
    {
      applyFilters,
      clearFilters,
      setFilters,
      filters,
      fields,
    },
  ]
}

export default useFilters

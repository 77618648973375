import React, { createContext, useReducer, useEffect } from 'react'
import { CSSProperties } from 'styled-components'

import theme from 'styles/theme'

import type {
  Flat,
  Building,
  City,
  Floor,
  Investment,
  OnFlatEnter,
  OnInvestmentEnter,
  OnCityEnter,
} from 'browser/types'

import reducer, { Action } from './reducer'
import generateGetters, { Getters } from './getters'

type StoreOuterProps = {
  outerState: {
    currentMode?: 'flats' | 'garages'
    investment: Investment
    building?: string
    allFlats: Flat[]
    flats: Flat[]
    onFlatEnter?: OnFlatEnter
    onInvestmentEnter?: OnInvestmentEnter
    onCityEnter?: OnCityEnter
  }
}

export type StateProps = {
  currentMode?: 'flats' | 'garages'
  investment: Investment | null
  building?: string | null
  currentBuilding: Building | null
  currentFloor: Floor | null
  currentCity: City | null
  currentUrl: string
  layoutHeight: CSSProperties['height']
  allFlats: Flat[]
  flats: Flat[]
  spriteTime: 'day' | 'mobile'
  onFlatEnter?: OnFlatEnter
  onInvestmentEnter?: OnInvestmentEnter
  onCityEnter?: OnCityEnter
}

type StoreProps = {
  state: StateProps
  dispatch: React.Dispatch<Action>
  getters: Getters
}

const getSpriteTime = () => {
  if (
    typeof window !== 'undefined' &&
    window.innerWidth < theme.breakpoints.lg
  ) {
    return 'mobile'
  }
  return 'day'
}

const initialState: StateProps = {
  currentMode: 'flats',
  investment: null,
  building: null,
  currentBuilding: null,
  currentFloor: null,
  currentCity: null,
  currentUrl: '/',
  layoutHeight: '100vh',
  allFlats: [],
  flats: [],
  spriteTime: 'day',
}

const store = createContext<StoreProps>({
  state: initialState,
  dispatch: () => null,
  getters: generateGetters(initialState),
})

const { Provider } = store

const StateProvider: React.FC<StoreOuterProps> = ({ outerState, children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...outerState,
    spriteTime: getSpriteTime(),
  })

  useEffect(() => {
    dispatch({ type: 'UPDATE_FLATS', payload: outerState.flats })
  }, [outerState.flats])

  useEffect(() => {
    dispatch({ type: 'UPDATE_ALL_FLATS', payload: outerState.allFlats })
  }, [outerState.allFlats])

  useEffect(() => {
    dispatch({ type: 'CHANGE_MODE', payload: outerState.currentMode })
  }, [outerState.currentMode])

  return (
    <Provider
      value={{
        state,
        dispatch,
        getters: generateGetters(state),
      }}
    >
      {children}
    </Provider>
  )
}

export { store, StateProvider as Provider }

import type {
  FilterField,
  FilterItemType,
  FilterItems,
  SelectFilter,
  RangeFilter,
  Range,
} from 'types/filters'

type FilterParams<T extends FilterItemType> = {
  key: FilterField['key']
  storageKey: string
  items: FilterItems<T>
  reset?: boolean
}

export const getInitialRangeFilter = <T extends FilterItemType>(
  type: T,
  params: FilterParams<T>
): RangeFilter => {
  const { key, storageKey, reset } = params
  let minValue = 30
  let maxValue = 120

  if (type === 'investment') {
    minValue = Math.min(
      ...params.items.map((item) => {
        const value = item[key] as Range
        return Math.floor(value.min)
      })
    )
    maxValue = Math.max(
      ...params.items.map((item) => {
        const value = item[key] as Range
        return Math.round(value.max)
      })
    )
  } else if (type === 'flat') {
    minValue = Math.min(
      ...params.items.map((flat) => Math.floor(flat[key] as number))
    )
    maxValue = Math.max(
      // @ts-ignore
      ...params.items.map((flat) => Math.round((flat[key] + 1) as number))
    )
  }
  const defaultValue = { min: minValue, max: maxValue }

  if (typeof window !== `undefined` && !reset) {
    const saved: RangeFilter['active'] | null = JSON.parse(
      sessionStorage.getItem(storageKey) ?? 'null'
    )
    if (saved) {
      return {
        default: defaultValue,
        active: {
          min: saved.min < defaultValue.min ? defaultValue.min : saved.min,
          max:
            saved.max > defaultValue.max ? defaultValue.max + 1 : saved.max + 1,
        },
      }
    }
  }

  return {
    default: defaultValue,
    active: defaultValue,
  }
}

export const getInitialSelectFilter = <T extends FilterItemType>(
  type: T,
  params: FilterParams<T>
): SelectFilter => {
  const { key, storageKey, reset } = params

  let defaultValue: string[] = []

  if (type === 'investment') {
    defaultValue = [
      ...new Set([
        ...params.items
          .map((filter) => {
            const value = filter[key]
            return Array.isArray(value) ? value : [value]
          })
          .flat(1),
      ]),
    ] as string[]
  } else if (type === 'flat') {
    defaultValue = [...new Set(params.items.map((flat) => flat[key] as string))]
  }

  if (typeof window !== `undefined` && !reset) {
    const saved: SelectFilter['active'] | null = JSON.parse(
      sessionStorage.getItem(storageKey) ?? 'null'
    )
    if (saved) {
      return {
        default: defaultValue,
        active: saved.filter((el) => defaultValue.includes(el)),
      }
    }
  }

  return {
    default: defaultValue,
    active: [],
  }
}

import React, { useEffect } from 'react'
import { MapProps } from 'browser/types'

const Map: React.FC<MapProps> = ({ onLoad }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <polygon
        data-floor="0"
        points="276 812 512 812 529 800 766.07 800 766.07 812 982 812 982 800 1073.79 800 1080.82 812 1294.05 812 1294.05 796.82 1707 786 1749 796 1920 786 1920 889 1518 923 282 937 282 928 276 928 276 812"
      />
      <polygon
        data-floor="1"
        points="276 812 512 812 529 800 766.07 800 766.07 812 982 812 982 800 1073.79 800 1080.82 812 1294.05 812 1294.05 796.82 1509.68 791.17 1707 786 1749 796 1920 786 1920 646 1900.51 646 1852 642 1294 645 1294 656 1079 657 1074 651 982 650 981 660 765 659 765 649 527 649 527 652 512 659 294 659 274 659 276 812"
      />
      <polygon
        data-floor="2"
        points="274 659 512 659 527 652 527 649 765 649 765 659 981 660 982 650 1074 651 1079 657 1294 656 1294 645 1852 642 1900.51 646 1920 646 1920 504 1752.34 504 1710.63 504 1295 504 1295 506 1075 506 978 506 490 506 274 506 274 659"
      />
      <polygon
        data-floor="3"
        points="274 506 1295 506 1295 504 1502.32 504 1640.38 504 1752.34 504 1920 504 1920 148 1786 141 1786 116 1784.52 116 1785 97 1693 92 1663 101 1663 118 1658 118 1658 135 911 114 911 86 907.19 86 907 66 792.2 66 792 87 789 87 789 113 354 113 270 313 274 506"
      />
    </svg>
  )
}

export default Map

import React, { useEffect } from 'react'
import { MapProps } from 'browser/types'

const Map: React.FC<MapProps> = ({ onLoad }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <path
        data-floor="0"
        d="M828.34,674,717,674l-36.72,0h-2v-2.1H639V669l-56-1-37-.22V665l-74.42-3H415l-126-6h64l-118.46-6.55H0V832.71L678.28,756v-9.19l157.52-5.73h82.7V674Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="1"
        d="M717,588.52h-1L680.28,588l36.72.52L674,579H628.16L591,573l49-1-55-11-51,1-41-8h55l-72-15-60,1L289,517l66-2L237,492l-71,1L2,467.85v182.6H236.54L355,657H291l126,6,58.43.07L548,666v2.78l37,.22,56,1v2.92h39.28V674L717,674H918.5V588.52Z"
        transform="translate(0 0)"
      />
      <path
        data-floor="2"
        d="M836.21,500,679,501l35-1-41-15H627l-37-11,49-1-55-20-52,1-40-12,55-2-73-25-60,2L287,375l66-2L237,332l-73,3L1,285.13V467.85L165,493l71-1,118,23-66,2,127,23,60-1,72,15H492l41,8,51-1,55,11-49,1,37.16,6H673l43,9.52H918.5L926,504Z"
        transform="translate(0 0)"
      />
      <polygon
        data-floor="3"
        points="1 285.13 164 335 237 332 353 373 287 375 414 417 474 415 547 440 492 442 532 454 584 453 639 473 590 474 627 485 673 485 714 500 836.21 500 926 504 924 451 882 273 593 288 593 260 556 237 520 239 520 244 242 80 242 50 158 0 112 3 109 0 0 0 1 285.13"
      />
    </svg>
  )
}

export default Map

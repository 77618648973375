import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { store } from 'browser/store'

import { useTranslation } from 'react-i18next'

type Props = {
  disabled?: boolean
}

const Nav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;

  background: rgba(255, 255, 255, 0.57);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.dark};
  font-size: 14px;
  @media (max-width: 991px) {
    display: none;
    /* background: #fff !important; */
  }
`

const NavArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-height: calc(60px * 0.9);
  max-width: calc(60px * 0.9);

  height: 40px;
  width: 40px;

  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primary};
  margin-right: 12px;

  transition: 0.3s;
  svg {
    width: 16px;
    height: 16px;
    display: block;
    transform: rotate(90deg);
    transition: 0.3s;
    margin: 0;
    fill: #fff;
  }
`

const NavBack = styled.button<{ disabled?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background: transparent;
  border: none;

  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
    `}
`

const NavText = styled.span`
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  @media (max-width: 576px) {
    display: none;
  }
`

const Navigation: React.FC<Props> = ({ disabled }) => {
  const history = useHistory()
  const { t } = useTranslation('browser')

  const {
    state: {
      currentMode,
      currentBuilding,
      currentFloor,
      currentCity,
      investment,
      building,
    },
  } = useContext(store)

  const isMultiBuilding =
    investment && investment.multiBuilding && investment.buildingsMap

  const goBack = () => {
    if (currentBuilding && currentFloor && currentMode !== 'garages') {
      history.push(`/building/${currentBuilding.url}`)
    } else if (currentBuilding) {
      history.push('/')
    } else if (currentMode === 'garages') {
      history.push('/')
    }
  }

  let topbarText = ''
  if (disabled) {
    topbarText = t('choose')
  } else if (
    currentBuilding &&
    currentFloor &&
    !currentFloor?.level.includes('-')
  ) {
    topbarText = t('choose_flat')
  } else if (currentFloor?.level.includes('-')) {
    topbarText = t('choose_parking_spot')
  } else if (currentBuilding) {
    topbarText = t('choose_floor')
  } else if (currentCity) {
    topbarText = t('choose_investment')
  } else if (investment && investment.multiBuilding) {
    topbarText = t('choose_building')
  } else {
    topbarText = t('choose_city')
  }

  return (
    <Nav>
      {!(building && !currentFloor) &&
        !!(
          (!isMultiBuilding && currentFloor) ||
          (isMultiBuilding && currentBuilding)
        ) && (
          <NavBack onClick={goBack} disabled={disabled}>
            <NavArrow>
              <svg
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="5.896"
                viewBox="0 0 10 5.896"
              >
                <path
                  d="M.435,5.46,4.975,10l.92-.92L1.828,5,5.9.92,4.975,0,.435,4.54,0,5Z"
                  transform="translate(0 5.896) rotate(-90)"
                />
              </svg>
            </NavArrow>
            {t('back')}
          </NavBack>
        )}

      <NavText>{topbarText}</NavText>
    </Nav>
  )
}

export default Navigation

import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { store } from 'browser/store'

import arrowRightIcon from 'browser/assets/icons/arrow-right-black.svg'

import { useTranslation } from 'react-i18next'

type Props = {
  disabled?: boolean
}

const BreadcrumbsWrapper = styled.div<{ disabled?: boolean }>`
  display: none;
  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 50;

    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    min-width: 20%;
    height: 40px;

    padding: 10px 40px;

    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
    ${({ disabled }) =>
      disabled &&
      css`
        pointer-events: none;
      `}
  }
`

const BreadcrumbsLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
`

const BreadcrumbsSpacing = styled.span`
  display: block;
  width: 7px;
  height: 11px;
  margin: 0 10px;
  background: url(${arrowRightIcon}) no-repeat center;
  background-size: 100%;
`

const Breadcrumbs: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation('browser')
  const {
    state: { investment, currentBuilding, currentFloor },
  } = useContext(store)

  const isMultiBuilding =
    investment?.multiBuilding || (investment?.buildingsMap && currentFloor)

  return (
    <>
      {investment && investment.investmentName && (
        <BreadcrumbsWrapper disabled={disabled}>
          <BreadcrumbsLink to="/">{investment.investmentName}</BreadcrumbsLink>
          {isMultiBuilding && currentBuilding && <BreadcrumbsSpacing />}
          {isMultiBuilding && currentBuilding && (
            <BreadcrumbsLink to={`/building/${currentBuilding.url}`}>
              {t('building')} {currentBuilding.name}
            </BreadcrumbsLink>
          )}
          {currentFloor && currentBuilding && <BreadcrumbsSpacing />}
          {currentFloor && currentBuilding && (
            <BreadcrumbsLink
              to={`/building/${currentBuilding.url}/floor/${currentFloor.level}`}
            >
              {t('floor')} {currentFloor.level}
            </BreadcrumbsLink>
          )}
        </BreadcrumbsWrapper>
      )}
    </>
  )
}

export default Breadcrumbs

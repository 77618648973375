import React, { useEffect } from 'react'
import { MapProps } from 'browser/types'

const Map: React.FC<MapProps> = ({ onLoad }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <polygon
        data-floor="0"
        points="81 714 115 717 416 718 416 720.88 807 726 807 762.32 417 767 417 772 117 781 81 773.58 81 714"
      />
      <polygon
        data-floor="1"
        points="81 714 115 717 416 718 416 720.88 807 726 807 682.46 756.65 682.46 744.51 682.46 494 676 494 669 419 667 384.35 667 385 654 292 650 288 653 128 654 81 654.81 81 714"
      />
    </svg>
  )
}

export default Map

import React, { useEffect } from 'react'
import { MapProps } from 'browser/types'

const Map: React.FC<MapProps> = ({ onLoad }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <polygon
        data-floor="0"
        points="1557 837 1486.37 837 1394.14 837 1310.97 837 1287 830 1105 826 1035 830 1020 830 801 842 751 843 719 845 732 847 675 850 634.77 850 574 853 636 855 636 934 1020 946 1557 936.6 1557 837"
      />
      <polygon
        data-floor="1"
        points="574 853 634.77 850 675 850 732 847 719 845 751 843 801 842 1020 830 1035 830 1105 826 1287 830 1310.97 837 1557 837 1557 808.3 1560 808.3 1560 805.54 1552.77 805.54 1552.77 764 1519.09 764 1519.09 717 1565 709 1565 708 1443 701 1417 702 1277 694 1259 681 1023 666 904 689 904 690 916 698 836 712 782 714 742 721 742 723 758 728 634 750 634 793 576 801 576 831 574 831 574 853"
      />
      <polygon
        data-floor="2"
        points="611 757 634 753.19 634 750 758 728 742 723 742 721 782 714 836 712 916 698 904 690 904 689 1023 666 1259 681 1277 694 1417 702 1443 701 1565 708 1567 708 1567 678.04 1563 678.04 1563 639 1518 636 1518 594 1563 581 1427 571 1415.28 571 1305 563 1269 537 1119 525 1049 543 1037 543 837 596 779 597 752 605 765 615 704 632 666 632 578 657 636 660 636 700 632 700 614 705 614 734 611 734 611 757"
      />
      <polygon
        data-floor="3"
        points="578 657 666 632 704 632 765 615 752 605 779 597 837 596 1037 543 1049 543 1119 525 1269 537 1305 563 1415.28 571 1427 571 1563 581 1564 581 1564 552 1565 552 1565 550.85 1560 551 1560 512 1474 504 1474 478 1487 473 1487 472 1479.87 472 1480 468 1337 449 1322 424 1018 380 790 482 790 484 810 501 683 554 683 555 687 555 687 556 662 566 662 567 687 569 687 575 668 575 581 604 581 633 578 633 578 657"
      />
      <polygon
        data-floor="4"
        points="662 566 687 556 687 555 683 555 683 554 810 501 790 484 790 482 1018 380 1322 424 1337 449 1480 468 1479.87 472 1487 472 1487 445 1488 445 1488 443.01 1480 443 1480 440 1481 440 1481 438 1448 434 1448 372 1520 336 1384 317 1323 326 1037 289 1030 293 1027 293 988 288 925 317 925 319 933 337 801 398 754 399 636 456 636 457.18 687 462 687 504 665 514 665 543 662 543 662 566"
      />
      <polygon
        data-floor="5"
        points="636 456 754 399 801 398 933 337 925 319 925 317 988 288 1027 293 1030 293 1037 289 1323 326 1384 317 1520 336 1520 307 1521 307 1521 305.05 1516 305.05 1516 267 1414 252 1414 190 1415.94 190 1415.94 187 1039 102 826 307 826 310 828 310 828 337 755 339 638 402 638 431 634 432 634 433.9 635 434 635 456 636 456"
      />
    </svg>
  )
}

export default Map

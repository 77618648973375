import React, { useContext, lazy, Suspense, useMemo } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'
import styled from 'styled-components'
import { store } from 'browser/store'

import Layout from 'browser/layout'
import Loader from 'browser/components/Loader'

const Investment = lazy(() => import('browser/views/Investment'))
const Building = lazy(() => import('browser/views/Building'))
const Floor = lazy(() => import('browser/views/Floor'))

const StyledAnimatedSwitch = styled(AnimatedSwitch)`
  position: relative;
  height: 100%;
  overflow: hidden;
  & > div {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    min-width: 100%;
    transform: translate3d(-50%, 0, 0);
    z-index: 1;
    & > div {
      min-width: 100%;
      height: 100%;
    }
  }
`

const Browser = () => {
  const { state } = useContext(store)
  const { investment } = state
  const isSSR = typeof window === 'undefined'

  const buildings = useMemo(
    () => (investment ? Array.from(investment.buildings, (el) => el[1]) : []),
    []
  )

  const buildingsDOM = useMemo(() => {
    return buildings.map((building) => (
      <Route exact path={`/building/${building.url}`} key={building.id}>
        {!isSSR && (
          <Suspense fallback={<div />}>
            <Building building={building} />
          </Suspense>
        )}
      </Route>
    ))
  }, [])

  const floorsDOM = useMemo(() => {
    const floors: JSX.Element[] = []

    buildings.forEach((building) => {
      building.floors.list.forEach((floor) => {
        floors.push(
          <Route
            exact
            path={`/building/${building.url}/floor/${floor.level}`}
            key={building.id + floor.level}
          >
            {!isSSR && (
              <Suspense fallback={<Loader visible fallback />}>
                <Floor currentFloor={floor} currentBuilding={building} />
              </Suspense>
            )}
          </Route>
        )
      })
    })

    return floors
  }, [])

  return (
    <>
      <Layout>
        <StyledAnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
        >
          <Route exact path="/">
            {investment &&
            investment.multiBuilding &&
            investment.buildingsMap ? (
              <>
                {!isSSR && (
                  <Suspense fallback={<Loader visible fallback />}>
                    <Investment />
                  </Suspense>
                )}
              </>
            ) : (
              <Redirect to={`/building/${buildings[0].url}`} />
            )}
          </Route>
          {buildingsDOM}
          {floorsDOM}
        </StyledAnimatedSwitch>
      </Layout>
    </>
  )
}

export default Browser

import type { Floors, MapProps } from 'browser/types'
import slugify from 'utils/slugify'

export type BuildingType = {
  name: string
  active: boolean
  floors: Floors
  spritespin: {
    loaderBackground?: string | null
    framesURL: {
      day: string
      mobile?: string
    }
    framesPerRow: number
    frames: number
    breakpoints: number[]
    maps: React.FC<MapProps>[]
    windroseInitialRotation: number
    reverse?: boolean
    frameTime?: number
  }
}

class Building {
  name: BuildingType['name']

  active: BuildingType['active']

  floors: BuildingType['floors']

  spritespin: BuildingType['spritespin'] & {
    sense: -1 | 1
  }

  id: string

  url: string

  constructor(data: BuildingType) {
    this.name = data.name // String (It is ID of buildings as well)
    this.id = slugify(this.name)
    this.url = this.id
    this.active = data.active // If building is accessible (works only if there are more than one building in the investment)
    this.floors = data.floors // Floors class

    this.spritespin = {
      loaderBackground: String(data.spritespin.loaderBackground) || null, // Background of the loader while the sprite is being loaded
      framesURL: {
        day: data.spritespin.framesURL.day,
        mobile:
          data.spritespin.framesURL.mobile || data.spritespin.framesURL.day,
      }, // String with sprite
      framesPerRow: Number(data.spritespin.framesPerRow), // How much frames are in each row
      frames: Number(data.spritespin.frames), // Number (1 - Infinity)
      breakpoints: [...data.spritespin.breakpoints], // Array of frames at which spinner stops
      maps: [...data.spritespin.maps], // Array of svg maps. Index in array = index of breakpoint
      windroseInitialRotation: Number(data.spritespin.windroseInitialRotation), // Number (0 - 360 deg)
      sense: data.spritespin.reverse ? -1 : 1, // Boolean
      frameTime: Number(data.spritespin.frameTime) || 40, // Spin speed
    }
  }
}

export default Building

import React, { useState, useMemo } from 'react'
import styled from 'styled-components'

import { Heading } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import HeaderFilters, {
  HeaderFilterItem,
} from 'components/layout/Headers/Secondary/Filters'
import Timeline from 'components/layout/Investment/LogBuilding/Timeline'
import Gallery from 'components/layout/LogBuilding/Post/Gallery'
import GalleryCarousel from 'components/layout/Carousels/GalleryCarousel'

import useBreakpoint from 'hooks/useBreakpoint'

import type Image from 'types/image'
import type { TimelineItem } from 'components/layout/Investment/LogBuilding/Timeline'

export type Stage = {
  timeline: TimelineItem[]
  gallery: Image[]
}

type Props = {
  heading: string
  stages: Stage[]
}

const Section = styled.section`
  position: relative;
  padding: 4rem 0;
  background: ${({ theme }) => theme.colors.white50};
  ${({ theme }) => theme.media.sm.max} {
    padding: 3rem 0;
  }
`

const FiltersWrapper = styled.div`
  & > div {
    padding: 0;
    & > div {
      margin: 0;
      border: none;
    }
  }
`

const CarouselWrapper = styled.div`
  .swiper {
    ${({ theme }) => theme.media.sm.max} {
      padding-left: ${({ theme }) => theme.container.padding};
      padding-right: ${({ theme }) => theme.container.padding};
    }
    .swiper-slide {
      width: 80vw;
      height: 50vw;
    }
  }
`

const InvestmentLogBuilding: React.FC<Props> = ({ heading, stages }) => {
  const [activeStage, setActiveStage] = useState(0)
  const { sm } = useBreakpoint()

  const OPTIONS: HeaderFilterItem[] = useMemo(
    () =>
      stages.map((_, index) => ({
        type: 'filter',
        label: `Etap ${index + 1}`,
        active: index === activeStage,
        callback: () => setActiveStage(index),
      })) || [],
    [stages, activeStage]
  )

  return (
    <Section>
      <Container slim>
        <Heading
          as="h2"
          dangerouslySetInnerHTML={{ __html: heading }}
          size={50}
          sizeDiff={0.7}
          weight={500}
        />
        <FiltersWrapper>
          <HeaderFilters options={OPTIONS} />
        </FiltersWrapper>
      </Container>
      <Timeline timeline={stages[activeStage].timeline} />
      {stages[activeStage].gallery.length > 0 && (
        <>
          {sm ? (
            <Gallery gallery={stages[activeStage].gallery} limit />
          ) : (
            <CarouselWrapper>
              <GalleryCarousel
                images={stages[activeStage].gallery}
                options={{
                  spaceBetween: 10,
                  slidesPerView: 'auto',
                }}
              />
            </CarouselWrapper>
          )}
        </>
      )}
    </Section>
  )
}

export default InvestmentLogBuilding

import slugify from 'utils/slugify'

import DRZYMALY_27 from 'browser/investments/drzymaly-27'
import ZACISZE_STRZESZYN from 'browser/investments/zacisze-strzeszyn'
import ZACISZE_STRZESZYN_GARAGE from 'browser/investments/zacisze-strzeszyn-garage'
import ART_MALTA from 'browser/investments/art-malta'
// import JAROCIN from 'browser/investments/jarocin'

import type { Investment } from 'browser/schemes'

const getInvestment = (investment: string): Investment | string | null => {
  switch (slugify(investment)) {
    case 'art-malta':
      return ART_MALTA
    case 'art-malta-smart-makieta':
      return `aefe1a56-82df-49ce-90bb-15777cf41f4b`
    case 'drzymaly-27':
      return DRZYMALY_27
    case 'zacisze-strzeszyn':
      return ZACISZE_STRZESZYN
    case 'zacisze-strzeszyn-garage':
      return ZACISZE_STRZESZYN_GARAGE
    // case 'jarocin':
    //   return JAROCIN
    default:
      return null
  }
}

export default getInvestment

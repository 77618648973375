import React, { useMemo } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'
import { LocalizedLink } from 'gatsby-theme-i18n'

import Container from 'components/shared/Container'
import Button from 'components/shared/Button'
import Select from 'components/layout/Filters/Select'

import useLocation from 'hooks/useLocation'
import useBreakpoint from 'hooks/useBreakpoint'

import type { Link } from 'constants/links'

export type HeaderFilterItem = {
  type: 'filter'
  label: string
  active: boolean
  callback: () => void
}

export type HeaderFilterOption = Link | HeaderFilterItem

type Props = {
  options: HeaderFilterOption[]
}

const Wrapper = styled.div`
  position: relative;
  padding: 0 0 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.xs.max} {
    padding-bottom: 1.5rem;
  }
`

const ButtonWrapper = styled.div<{ active: boolean }>`
  padding: 0 0.5rem 0.5rem 0;
  min-width: 200px;
  ${({ theme }) => theme.media.sm.max} {
    min-width: 50%;
  }
  ${({ theme }) => theme.media.xs.max} {
    min-width: 100%;
  }
  & > ${Button} {
    &:hover {
      background: ${({ theme, active }) =>
        active ? theme.colors.primary : theme.colors.white};
      color: ${({ theme, active }) =>
        active ? theme.colors.white : theme.colors.primary};
    }
  }
`

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
  & > div {
    border-color: #d6d6d6;
  }
  ${({ theme }) => theme.media.xs.min} {
    display: none;
  }
`

const HeaderFilters: React.FC<Props> = ({ options }) => {
  const pathname = useLocation(true)
  const { xs } = useBreakpoint()

  const selectOptions = useMemo(
    () =>
      options.map((option) => ({
        label: option.label,
        value: option.label,
        callback: () => {
          if (option.type === 'filter') {
            option.callback()
            return
          }
          navigate(option.link)
        },
      })),
    [options]
  )

  const activeSelectOptions = useMemo(
    () =>
      options
        .filter((option) =>
          option.type === 'local'
            ? pathname.includes(option.link)
            : option.active
        )
        .map(({ label }) => ({
          label,
          value: label,
        })),
    [options]
  )

  return (
    <Container>
      <Wrapper>
        {xs ? (
          options.map((option) => {
            const { type, label } = option
            const isActive =
              type === 'local' ? pathname.includes(option.link) : option.active
            return (
              <ButtonWrapper active={isActive} key={label}>
                <Button
                  as={type === 'local' ? LocalizedLink : Button}
                  to={type === 'local' && option.link}
                  outline={!isActive}
                  darkgray={!isActive}
                  color={isActive ? 'primary' : 'darkgray'}
                  width="100%"
                  onClick={() => type === 'filter' && option.callback()}
                >
                  {label}
                </Button>
              </ButtonWrapper>
            )
          })
        ) : (
          <SelectWrapper>
            <Select
              defaultOptions={selectOptions}
              activeOptions={activeSelectOptions}
            />
          </SelectWrapper>
        )}
      </Wrapper>
    </Container>
  )
}

export default HeaderFilters

import React, { useContext } from 'react'
import styled, { css, CSSProperties } from 'styled-components'
import { store } from 'browser/store'
import useBreakpoint from 'hooks/useBreakpoint'
import useLocation from 'hooks/useLocation'

// Layout Elements
import Nav from 'browser/layout/Nav'
import Breadcrumbs from 'browser/layout/Breadcrumbs'
import Powered from 'browser/layout/Powered'

type LayoutProps = {
  isBuilding: boolean
  height: CSSProperties['height']
}

const LayoutWrapper = styled.div<LayoutProps>`
  position: relative;
  width: 100%;
  background: #fff;
  height: ${({ height }) => height || '100vh'};
  @media (max-width: 439px) {
    height: 100vw !important;
  }
`

/*  ${({ isBuilding }) =>
isBuilding &&
css`
  @media (max-width: 439px) {
    height: calc(120vw + 60px) !important;
  }
`} */

const LayoutMain = styled.main`
  height: 100%;
`

const Layout: React.FC = ({ children }) => {
  const { lg } = useBreakpoint()
  const {
    state: { layoutHeight, currentBuilding, currentFloor },
  } = useContext(store)
  const location = useLocation()

  return (
    <LayoutWrapper
      height={layoutHeight}
      isBuilding={Boolean(currentBuilding && !currentFloor)}
    >
      <Nav disabled={location === '/art-malta'} />
      {lg && (
        <Breadcrumbs
          disabled={
            location === '/art-malta' || location === '/zacisze-strzeszyn'
          }
        />
      )}
      <LayoutMain>{children}</LayoutMain>
      <Powered />
    </LayoutWrapper>
  )
}

export default Layout

import ReactDOM from 'react-dom'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import usePortal from 'browser/hooks/usePortal'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

type LoaderProps = {
  fallback?: boolean
  visible: boolean
  withBg?: boolean
  background?: string | null
  text?: string
}

const LoaderWrapper = styled.div<LoaderProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ fallback }) => (fallback ? '9999' : '6')};
  opacity: 1;
  transition: opacity 350ms ease-in-out;
  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ withBg }) => (withBg ? `#efefef` : 'transparent')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({ background }) =>
    background &&
    css`
      background-image: url('${background}');
    `}
`

const Spinner = styled.figure`
  position: relative;
  display: inline-block;
  min-width: 80px;
  min-height: 80px;
  margin: 0;
  & > div {
    position: absolute;
    min-width: 64px;
    min-height: 64px;
    border: 6px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme }) => theme.colors.primary} transparent transparent
      transparent;
    animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

const LoaderText = styled.span`
  margin-top: 8px;
  font-weight: 500;
`

const LoaderComponent: React.FC<LoaderProps> = ({
  visible,
  text,
  fallback,
  background,
  withBg,
}) => (
  <LoaderWrapper
    key="spinner"
    fallback={fallback}
    visible={visible}
    background={background}
    withBg={withBg}
  >
    {visible && (
      <>
        <Spinner>
          <div />
          <div />
          <div />
          <div />
        </Spinner>
        <LoaderText>{text}</LoaderText>
      </>
    )}
  </LoaderWrapper>
)

const Loader: React.FC<LoaderProps> = ({
  visible,
  text,
  fallback,
  background,
  withBg,
}) => {
  const target = usePortal('#browser')

  return (
    <>
      {fallback && target ? (
        ReactDOM.createPortal(
          <LoaderComponent
            visible={visible}
            text={text}
            fallback={fallback}
            background={background}
            withBg={withBg}
          />,
          target
        )
      ) : (
        <LoaderComponent
          visible={visible}
          text={text}
          fallback={fallback}
          background={background}
          withBg={withBg}
        />
      )}
    </>
  )
}

export default Loader

import { useEffect, useRef } from 'react'

const usePortal = (selector: string) => {
  const rootElemRef = useRef<HTMLElement>(null)

  useEffect(
    function setupElement() {
      const parentElement = document.querySelector(selector)
      const rootElement = rootElemRef.current

      if (
        rootElement instanceof HTMLElement &&
        parentElement instanceof HTMLElement
      ) {
        parentElement.appendChild(rootElement)
        return function removeElement() {
          rootElement.remove()
        }
      }
      return () => null
    },
    [selector]
  )

  if (typeof window !== `undefined` && selector === 'body') {
    return document.querySelector('body')
  }

  return rootElemRef.current
}

export default usePortal

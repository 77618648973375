import { Investment, Building, Floors, Floor } from 'browser/schemes'

import buildingImg from './assets/building.webp'
import buildingsMap from './maps'

import { ReactComponent as p0 } from './maps/A/p0.svg'
import { ReactComponent as p1 } from './maps/A/p1.svg'
import { ReactComponent as p2 } from './maps/A/p2.svg'
import { ReactComponent as p3 } from './maps/A/p3.svg'
import { ReactComponent as p4 } from './maps/A/p4.svg'
import { ReactComponent as p5 } from './maps/A/p5.svg'

import p0BG from './assets/A/p0.png'
import p1BG from './assets/A/p1.png'
import p2BG from './assets/A/p2.png'
import p3BG from './assets/A/p3.png'
import p4BG from './assets/A/p4.png'
import p5BG from './assets/A/p5.png'

const floorsA = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
  windroseRotation: -20,
  list: [
    new Floor({
      level: '0',
      map: p0,
      backgroundImage: p0BG,
    }),
    new Floor({
      level: '1',
      map: p1,
      backgroundImage: p1BG,
    }),
    new Floor({
      level: '2',
      map: p2,
      backgroundImage: p2BG,
    }),
    new Floor({
      level: '3',
      map: p3,
      backgroundImage: p3BG,
    }),
    new Floor({
      level: '4',
      map: p4,
      backgroundImage: p4BG,
    }),
    new Floor({
      level: '5',
      map: p5,
      backgroundImage: p5BG,
    }),
  ],
})

const A = new Building({
  name: 'A',
  active: true,
  spritespin: {
    framesURL: {
      day: buildingImg,
    },
    framesPerRow: 1,
    frames: 1,
    breakpoints: [0],
    maps: [buildingsMap],
    windroseInitialRotation: -20,
  },
  floors: floorsA,
})

const buildings = new Map()
buildings.set(A.id, A)

const investment = new Investment({
  investmentName: 'Drzymały 27',
  buildings,
})

export default investment

import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

// import Header from 'components/layout/Headers/Secondary'
import InvestmentBrowser from 'components/layout/Investment/Browser'
import InvestmentAbout from 'components/layout/Investment/About'
import InvestmentGallery from 'components/layout/Investment/Gallery'
import InvestmentAdvantages from 'components/layout/Investment/Advantages'
import InvestmentLocation from 'components/layout/Investment/Location'
import InvestmentLogBuilding, {
  Stage,
} from 'components/layout/Investment/LogBuilding'
import Map from 'components/layout/Maps'

import type { InvestmentTemplateQuery } from 'types/graphql'
import type Image from 'types/image'
import type { AdvantageCard } from 'components/layout/Investment/Advantages/Card'
import type { Stat } from 'components/layout/Sections/Homepage/Statistics/SingleStat'
import type { Marker } from 'components/layout/Maps/Legend'
import type { Flat } from 'types/flat'

const InvestmentTemplate: React.FC<PageProps<InvestmentTemplateQuery>> = ({
  data,
}) => {
  const INVESTMENT = data.investment?.investmentFields
  const INVESTMENT_SEO = data.investment?.seo
  const INVESTMENT_NAME = data.investment?.title
  const FLATS = data.flats.nodes as Flat[]
  const GARAGES = data.garages.nodes as Flat[]
  const STORAGE_ROOMS = data.storageRooms.nodes as Flat[]

  if (!INVESTMENT || !INVESTMENT_SEO || !INVESTMENT_NAME)
    throw new Error("CMS data didn't load properly")

  // const HEADER_IMAGES: Image[] = useMemo(
  //   () =>
  //     INVESTMENT.investmentS1Slider?.map((slide) => ({
  //       src: slide?.investmentS1SliderElementImg?.localFile?.childImageSharp
  //         ?.gatsbyImageData!,
  //       alt: slide?.investmentS1SliderElementImg?.altText!,
  //     })) || [],
  //   []
  // )

  const GALLERY_IMAGES: Image[] = useMemo(
    () =>
      INVESTMENT.investmentS5Slider?.map((slide) => ({
        src: slide?.investmentS5SliderElementImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: slide?.investmentS5SliderElementImg?.altText!,
      })) || [],
    []
  )

  const ADVANTAGES_CARDS: AdvantageCard[] = useMemo(
    () =>
      INVESTMENT.investmentS6Slider?.map((slide) => ({
        heading: slide?.investmentS6SliderElementTitle!,
        description: slide?.investmentS6SliderElementDescription!,
        image: {
          src: slide?.investmentS6SliderElementImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.investmentS6SliderElementImg?.altText!,
        },
      })) || [],
    []
  )

  const LOCATION_ITEMS: Stat[] = useMemo(
    () =>
      INVESTMENT.investmentS7List?.map((stat) => ({
        icon: stat?.investmentS7ListElementIcon!,
        value: stat?.investmentS7ListElementTitle!,
        label: stat?.investmentS7ListElementDescription!,
      })) || [],
    []
  )

  const LOG_BUILDING_STAGES: Stage[] = useMemo(
    () =>
      INVESTMENT.investmentS8List?.map((stage) => ({
        timeline:
          stage?.investmentS8ListElementStages?.map((item) => ({
            text: item?.investmentS8ListElementStagesElementTitle!,
            active: item?.investmentS8ListElementStagesElementActive!,
          })) || [],
        gallery:
          stage?.investmentS8ListElementGallery?.map((image) => ({
            src: image?.investmentS8ListElementGalleryElementImg?.localFile
              ?.childImageSharp?.gatsbyImageData!,
            alt: image?.investmentS8ListElementGalleryElementImg?.altText!,
          })) || [],
      })) || [],
    []
  )

  const MAP_MARKERS: Marker[] = useMemo(
    () =>
      INVESTMENT.investmentMap?.investmentMapMarkers?.map(
        (marker) =>
          ({
            type: marker?.investmentMapMarkersCategory,
            lat: Number(marker?.investmentMapMarkersLat),
            lng: Number(marker?.investmentMapMarkersLng),
          } as Marker)
      ) || [],
    []
  )

  return (
    <Layout>
      <SEO seo={INVESTMENT_SEO} />
      <Breadcrumbs
        crumbs={[
          { name: 'Oferta mieszkań', to: '/oferta-mieszkan' },
          { name: INVESTMENT_NAME },
        ]}
      />
      {/* <Header
        title={INVESTMENT.investmentS1Title!}
        text={INVESTMENT.investmentS1Text!}
        button={{
          link: INVESTMENT.investmentS1Cta?.url!,
          text: INVESTMENT.investmentS1Cta?.title!,
        }}
        image={HEADER_IMAGES}
      /> */}
      <main>
        {FLATS.length > 0 && (
          <InvestmentBrowser
            investment={INVESTMENT_NAME!}
            flats={FLATS}
            garages={[...GARAGES, ...STORAGE_ROOMS]}
          />
        )}
        <InvestmentAbout
          heading={INVESTMENT.investmentS4Title!}
          intro={INVESTMENT.investmentS4Description!}
          image={{
            src: INVESTMENT.investmentS4List![0]!.investmentS4ListElementImg
              ?.localFile?.childImageSharp?.gatsbyImageData!,
            alt: INVESTMENT.investmentS4List![0]!.investmentS4ListElementImg
              ?.altText!,
          }}
          description={
            INVESTMENT.investmentS4List![0]!.investmentS4ListElementDescription!
          }
        />
        <InvestmentGallery
          title={INVESTMENT.investmentS5Title!}
          images={GALLERY_IMAGES}
        />
        <InvestmentAdvantages
          title={INVESTMENT.investmentS6Title!}
          list={ADVANTAGES_CARDS}
        />
        <InvestmentLocation
          heading={INVESTMENT.investmentS7Title!}
          description={INVESTMENT.investmentS7Description!}
          image={{
            src: INVESTMENT.investmentS7Img?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: INVESTMENT.investmentS7Img?.altText!,
          }}
          list={LOCATION_ITEMS}
        />
        <Map
          center={{
            lat: Number(INVESTMENT.investmentMap?.investmentMapLat),
            lng: Number(INVESTMENT.investmentMap?.investmentMapLng),
          }}
          zoom={15}
          investments={[
            {
              name: INVESTMENT_NAME!,
              image:
                INVESTMENT.investmentMapImg!.localFile!.childImageSharp!
                  .gatsbyImageData!,
              lat: Number(INVESTMENT.investmentMap?.investmentMapLat),
              lng: Number(INVESTMENT.investmentMap?.investmentMapLng),
            },
          ]}
          markers={MAP_MARKERS}
        />
        {LOG_BUILDING_STAGES.length > 0 && (
          <InvestmentLogBuilding
            heading={INVESTMENT.investmentS8Title!}
            stages={LOG_BUILDING_STAGES}
          />
        )}
      </main>
    </Layout>
  )
}

export default InvestmentTemplate

export const query = graphql`
  query InvestmentTemplate($slug: String) {
    flats: allFlats(filter: { investmentSlug: { eq: $slug } }) {
      nodes {
        ...FlatsFragment
      }
    }
    garages: allGarages(filter: { investmentSlug: { eq: $slug } }) {
      nodes {
        ...GaragesFragment
      }
    }
    storageRooms: allStorageRooms(filter: { investmentSlug: { eq: $slug } }) {
      nodes {
        ...StorageRoomsFragments
      }
    }
    investment: wpInvestment(slug: { eq: $slug }) {
      seo {
        ...WpSEO
      }
      title
      investmentFields {
        investmentS1Title
        investmentS1Text
        investmentS1Cta {
          title
          url
        }
        investmentS1Slider {
          investmentS1SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        investmentS4Title
        investmentS4Description
        investmentS4List {
          investmentS4ListElementDescription
          investmentS4ListElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        investmentS5Title
        investmentS5Slider {
          investmentS5SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        investmentS6Title
        investmentS6Slider {
          investmentS6SliderElementTitle
          investmentS6SliderElementDescription
          investmentS6SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        investmentS7Title
        investmentS7Description
        investmentS7Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        investmentS7List {
          investmentS7ListElementTitle
          investmentS7ListElementDescription
          investmentS7ListElementIcon
        }
        investmentS8Title
        investmentS8List {
          investmentS8ListElementStages {
            investmentS8ListElementStagesElementActive
            investmentS8ListElementStagesElementTitle
          }
          investmentS8ListElementGallery {
            investmentS8ListElementGalleryElementImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1900
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
        investmentMap {
          investmentMapLng
          investmentMapLat
          investmentMapBoxDirection
          investmentMapMarkers {
            investmentMapMarkersCategory
            investmentMapMarkersLat
            investmentMapMarkersLng
          }
        }
        investmentMapImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`

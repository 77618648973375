import { navigate } from 'gatsby'

declare let SM: any

const injectSmartMakieta = (
  investment: string,
  setInstance: (v: any) => void
) => {
  if (typeof window !== `undefined` && investment) {
    const link = document.createElement('link')
    link.href = `https://3destatesmartmakietaemb.z6.web.core.windows.net/v1/css/main.css`
    link.rel = `stylesheet`
    document.head.appendChild(link)

    const script = document.createElement('script')
    script.src = `https://3destatesmartmakietaemb.z6.web.core.windows.net/v1/js/main.js`
    script.onload = () => {
      const instance = SM.init(
        investment,
        'smart-makieta',
        {},
        {
          disableCompare: true,
          disableSendContactRequest: true,
          disableRightClick: false,
          gallery: false,
          location: false,
          hideFlatsSold: true,
          disableRightPanelOnDesktop: true,
        },
        {},
        {
          onAnalyticEvent: (event: any) => {
            if (event.event === 'flatMediaNav3dDollhouse') {
              navigate(`1-${event.data.flatId}`)
            }
          },
        }
      )
      setInstance(instance)
    }
    document.body.appendChild(script)
    return () => {
      link.remove()
      script.remove()
    }
  }
  return () => null
}

export default injectSmartMakieta

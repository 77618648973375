import { StateProps } from 'browser/store'

export type Action =
  | { type: 'CHANGE_CITY'; payload: StateProps['currentCity'] }
  | { type: 'CHANGE_BUILDING'; payload: StateProps['currentBuilding'] }
  | { type: 'CHANGE_FLOOR'; payload: StateProps['currentFloor'] }
  | { type: 'CHANGE_SPRITE_TIME'; payload: StateProps['spriteTime'] }
  | {
      type: 'CHANGE_LAYOUT_HEIGHT'
      payload?: StateProps['layoutHeight']
    }
  | { type: 'UPDATE_ALL_FLATS'; payload: StateProps['allFlats'] }
  | { type: 'UPDATE_FLATS'; payload: StateProps['flats'] }
  | { type: 'CHANGE_URL'; payload: StateProps['currentUrl'] }
  | { type: 'CHANGE_MODE'; payload: StateProps['currentMode'] }

const reducer = (state: StateProps, action: Action) => {
  let newState: StateProps = state

  switch (action.type) {
    case 'CHANGE_CITY':
      newState = { ...state, currentCity: action.payload }
      return newState
    case 'CHANGE_BUILDING':
      newState = { ...state, currentBuilding: action.payload }
      return newState
    case 'CHANGE_FLOOR':
      newState = { ...state, currentFloor: action.payload }
      return newState
    case 'CHANGE_SPRITE_TIME':
      newState = { ...state, spriteTime: action.payload }
      return newState
    case 'CHANGE_LAYOUT_HEIGHT':
      newState = {
        ...state,
        layoutHeight: action.payload || '100vh',
      }
      return newState
    case 'UPDATE_ALL_FLATS':
      newState = { ...state, allFlats: action.payload }
      return newState
    case 'UPDATE_FLATS':
      newState = { ...state, flats: action.payload }
      return newState
    case 'CHANGE_URL':
      if (typeof sessionStorage !== 'undefined' && state.investment) {
        sessionStorage.setItem(
          state.building
            ? `url-${state.investment.url}-${state.building}`
            : `url-${state.investment.url}`,
          action.payload
        )
      }
      newState = { ...state, currentUrl: action.payload }
      return newState
    case 'CHANGE_MODE':
      newState = { ...state, currentMode: action.payload }
      return newState
    default:
      return state
  }
}

export default reducer

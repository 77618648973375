import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Container from 'components/shared/Container'
import { Col, ColSize, Row } from 'components/shared/Grid'

import Button from 'components/shared/Button'
import Select from 'components/layout/Filters/Select'
import RangeInput from 'components/layout/Filters/RangeInput'

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'

import scrollToSection from 'utils/scrollToSection'
import useLocation from 'hooks/useLocation'
import { navigate } from 'gatsby'

import type { FilterField, FiltersState, Range } from 'types/filters'

type Props = {
  filters: FiltersState
  includedFilters: FilterField[]
  setFilters: (filters: FiltersState) => void
  applyFilters: () => void
  filtersHome?: boolean
}

const Wrapper = styled.div`
  position: relative;
  z-index: 11;
  & > ${Container} {
    & > ${Row} {
      margin: 0;
    }
  }
`

const StyledCol = styled(Col)<{ index?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  min-height: 60px;
  padding: 0;
  z-index: ${({ index }) => index ?? '0'};
  ${({ theme }) => theme.media.sm.min} {
    height: 100px;
  }
`

const ButtonCol = styled(StyledCol)`
  border: none !important;
  padding: 0;
  background: transparent;
  & > ${Button} {
    height: 100%;
    border: none;
    font-size: 15px;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    &:hover {
      background: ${({ theme }) => theme.colors.primary100};
    }
  }
  ${({ theme }) => theme.media.sm.max} {
    margin-top: 0.5rem;
  }
`

const RangeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: 1px solid #ececec;
  ${({ theme }) => theme.media.sm.max} {
    padding: 0.75rem 1rem;
  }
`

const Filters: React.FC<Props> = ({
  filters,
  setFilters,
  applyFilters,
  includedFilters,
  filtersHome,
}) => {
  const { t } = useTranslation('filters')
  const pathname = useLocation(true)

  const handleSetFilters = (
    key: keyof FiltersState,
    newActiveOptions: string[] | Range
  ) =>
    setFilters({
      ...filters,
      [key]: {
        ...filters[key],
        active: newActiveOptions,
      },
    })

  const handleRedirectWithFilters = () => {
    navigate('/znajdz-mieszkanie', {
      state: {
        filters,
        includedFilters,
      },
    })
  }

  return (
    <Wrapper>
      <Container slim filtersHome={filtersHome}>
        <Row fullHeight>
          {includedFilters.map(({ key, type }, index) => {
            const filter = filters[key]

            if (!filter) return null
            return (
              <StyledCol
                index={includedFilters.length - index}
                key={key}
                xs={24}
                sm={12}
                md={8}
                xxl={Math.round(24 / includedFilters.length - 1) as ColSize}
              >
                {type === 'select' && (
                  <Select
                    label={t(key)}
                    placeholder={t('selectFromList')}
                    setNewOptions={(newOptions) =>
                      handleSetFilters(key, newOptions)
                    }
                    defaultOptions={(filter.default as string[])
                      .sort()
                      .map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    activeOptions={(filter.active as string[])
                      .sort()
                      .map((item) => ({
                        value: item,
                        label: item,
                      }))}
                  />
                )}
                {type === 'range' && (
                  <RangeInputWrapper>
                    <RangeInput
                      label={t(key)}
                      extremes={filter.default as Range}
                      value={filter.active as Range}
                      onChange={(value) => handleSetFilters(key, value)}
                      minDistance={5}
                    />
                  </RangeInputWrapper>
                )}
              </StyledCol>
            )
          })}
          <ButtonCol xs={24} sm={12} md={8} xxl={4}>
            <Button
              onClick={() => {
                if (pathname === '/') {
                  handleRedirectWithFilters()
                  return
                }
                applyFilters()
                scrollToSection('#filter-items')
              }}
              width="100%"
            >
              <SearchIcon /> {t('findApartment')}
            </Button>
          </ButtonCol>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default Filters

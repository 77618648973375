import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { MemoryRouter, Route } from 'react-router-dom'
import { Provider as KeepAliveProvider } from 'react-keep-alive'

import Browser from 'browser/Browser'
import Loader from 'browser/components/Loader'
import { Provider as StoreProvider } from 'browser/store'

import type {
  Flat,
  Investment,
  OnFlatEnter,
  OnInvestmentEnter,
  OnCityEnter,
} from 'browser/types'

type AppProps = {
  currentMode?: 'flats' | 'garages'
  allFlats: Flat[]
  filteredFlats: Flat[]
  investment: Investment
  building?: string
  isLoading: boolean
  initialRoute: string
  customRoute?: string
  loadingText?: string
  onFlatEnter?: OnFlatEnter
  onInvestmentEnter?: OnInvestmentEnter
  onCityEnter?: OnCityEnter
}

type WrapperProps = {
  full: boolean
  auto: boolean
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: 100%;
  height: ${({ full }) => (full ? '100vh' : 'calc(120vw + 65px)')};
  overflow: hidden;
  background: #efefef;
  ${({ auto }) =>
    auto &&
    css`
      height: 100% !important;
    `}
  ${({ theme }) => theme.media.lg.min} {
    height: 100vh !important;
  }
`

const App: React.FC<AppProps> = ({
  currentMode,
  investment,
  building,
  allFlats,
  filteredFlats,
  isLoading,
  loadingText,
  initialRoute,
  customRoute,
  onFlatEnter,
  onInvestmentEnter,
  onCityEnter,
}) => {
  const savedRoute = useMemo(
    () =>
      typeof sessionStorage !== 'undefined'
        ? sessionStorage.getItem(
            building
              ? `url-${investment.url}-${building}`
              : `url-${investment.url}`
          )
        : false,
    [investment]
  )
  return (
    <Wrapper id="browser" full={investment.multiBuilding} auto={!isLoading}>
      <MemoryRouter
        initialEntries={[customRoute || savedRoute || initialRoute]}
      >
        <Route>
          {!isLoading && (
            <StoreProvider
              outerState={{
                currentMode,
                investment,
                building,
                allFlats,
                flats: filteredFlats,
                onFlatEnter,
                onInvestmentEnter,
                onCityEnter,
              }}
            >
              <KeepAliveProvider>
                <Browser />
              </KeepAliveProvider>
            </StoreProvider>
          )}
          <Loader withBg visible={isLoading} text={loadingText} />
        </Route>
      </MemoryRouter>
    </Wrapper>
  )
}

export default App

import React from 'react'

import App from 'browser/App'

import type {
  Flat,
  Investment,
  OnFlatEnter,
  OnInvestmentEnter,
  OnCityEnter,
} from 'browser/types'

type BrowserProps = {
  currentMode?: 'flats' | 'garages'
  allFlats?: Flat[]
  filteredFlats?: Flat[]
  initialRoute?: string
  customRoute?: string
  investment: Investment | null
  building?: string
  loading?: boolean
  loadingText?: string
  onFlatEnter?: OnFlatEnter
  onInvestmentEnter?: OnInvestmentEnter
  onCityEnter?: OnCityEnter
}

const BrowserWrapper: React.FC<BrowserProps> = ({
  currentMode,
  allFlats = [],
  filteredFlats = [],
  investment,
  building,
  loading = false,
  loadingText,
  initialRoute = '/',
  customRoute,
  onFlatEnter,
  onInvestmentEnter,
  onCityEnter,
}) => {
  return (
    <>
      {investment && (
        <App
          currentMode={currentMode}
          allFlats={allFlats}
          filteredFlats={filteredFlats}
          investment={investment}
          building={building}
          isLoading={loading}
          loadingText={loadingText}
          initialRoute={initialRoute}
          customRoute={customRoute}
          onFlatEnter={onFlatEnter}
          onInvestmentEnter={onInvestmentEnter}
          onCityEnter={onCityEnter}
        />
      )}
    </>
  )
}

export default BrowserWrapper

/* eslint-disable no-param-reassign */
const numerals = [
  ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'],
  ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'],
  ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'],
  ['M', 'MM', 'MMM'],
]

const convertToRoman = (original: number) => {
  if (original < 1 || original > 3999) {
    return original
  }

  const digits = Math.round(original).toString().split('')
  let position = digits.length - 1

  return digits.reduce((roman, digit) => {
    if (digit !== '0') roman += numerals[position][Number(digit) - 1]
    position -= 1
    return roman
  }, '')
}

export default convertToRoman

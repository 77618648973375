import { Investment, Building, Floors, Floor } from 'browser/schemes'

import buildingsBackground from './assets/investment.jpg'
import { ReactComponent as buildingsMap } from './maps/investment.svg'
import buildingAMap from './maps/A'
import buildingAImg from './assets/A/building.jpg'

import { ReactComponent as pAn1 } from './maps/A/p-1.svg'
import { ReactComponent as pAn2 } from './maps/A/p-2.svg'

import pAn1BG from './assets/A/p-1.png'
import pAn2BG from './assets/A/p-2.png'

const floorsA = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
  windroseRotation: 0,
  list: [
    new Floor({
      level: '-2',
      map: pAn2,
      backgroundImage: pAn2BG,
    }),
    new Floor({
      level: '-1',
      map: pAn1,
      backgroundImage: pAn1BG,
    }),
  ],
})

const A = new Building({
  name: 'A',
  active: true,
  spritespin: {
    framesURL: {
      day: buildingAImg,
    },
    framesPerRow: 1,
    frames: 1,
    breakpoints: [0],
    maps: [buildingAMap],
    windroseInitialRotation: 20,
  },
  floors: floorsA,
})

const buildings = new Map()
buildings.set(A.id, A)

const investment = new Investment({
  investmentName: 'Art Malta',
  buildings,
  buildingsMap,
  buildingsBackground,
})

export default investment

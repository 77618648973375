import { Investment, Building, Floors, Floor } from 'browser/schemes'

import buildingsBackground from './assets/investment.jpg'
import { ReactComponent as buildingsMap } from './maps/investment.svg'

import buildingAMap from './maps/A'
import buildingBMap from './maps/B'
import buildingCMap from './maps/C'
import buildingDMap from './maps/D'

import buildingAImg from './assets/A/building.jpg'
import buildingBImg from './assets/B/building.jpg'
import buildingCImg from './assets/C/building.jpg'
import buildingDImg from './assets/D/building.jpg'

import { ReactComponent as pAn1 } from './maps/A/p-1.svg'
import { ReactComponent as pA0 } from './maps/A/p0.svg'
import { ReactComponent as pA1 } from './maps/A/p1.svg'
import { ReactComponent as pA2 } from './maps/A/p2.svg'
import { ReactComponent as pA3 } from './maps/A/p3.svg'

import { ReactComponent as pBn1 } from './maps/B/p-1.svg'
import { ReactComponent as pB0 } from './maps/B/p0.svg'
import { ReactComponent as pB1 } from './maps/B/p1.svg'
import { ReactComponent as pB2 } from './maps/B/p2.svg'
import { ReactComponent as pB3 } from './maps/B/p3.svg'

import { ReactComponent as pC0 } from './maps/C/p0.svg'
import { ReactComponent as pC1 } from './maps/C/p1.svg'
import { ReactComponent as pC2 } from './maps/C/p2.svg'
import { ReactComponent as pC3 } from './maps/C/p3.svg'

import { ReactComponent as pD0 } from './maps/D/p0.svg'
import { ReactComponent as pD1 } from './maps/D/p1.svg'
import { ReactComponent as pD2 } from './maps/D/p2.svg'
import { ReactComponent as pD3 } from './maps/D/p3.svg'

import pAn1BG from './assets/A/p-1.png'
import pA0BG from './assets/A/p0.png'
import pA1BG from './assets/A/p1.png'
import pA2BG from './assets/A/p2.png'
import pA3BG from './assets/A/p3.png'

import pBn1BG from './assets/B/p-1.png'
import pB0BG from './assets/B/p0.png'
import pB1BG from './assets/B/p1.png'
import pB2BG from './assets/B/p2.png'
import pB3BG from './assets/B/p3.png'

import pC0BG from './assets/C/p0.png'
import pC1BG from './assets/C/p1.png'
import pC2BG from './assets/C/p2.png'
import pC3BG from './assets/C/p3.png'

import pD0BG from './assets/D/p0.png'
import pD1BG from './assets/D/p1.png'
import pD2BG from './assets/D/p2.png'
import pD3BG from './assets/D/p3.png'

const floorsA = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
  windroseRotation: 20,
  list: [
    new Floor({
      level: '-1',
      map: pAn1,
      backgroundImage: pAn1BG,
    }),
    new Floor({
      level: '0',
      map: pA0,
      backgroundImage: pA0BG,
    }),
    new Floor({
      level: '1',
      map: pA1,
      backgroundImage: pA1BG,
    }),
    new Floor({
      level: '2',
      map: pA2,
      backgroundImage: pA2BG,
    }),
    new Floor({
      level: '3',
      map: pA3,
      backgroundImage: pA3BG,
    }),
  ],
})

const floorsB = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
  windroseRotation: 20,
  list: [
    new Floor({
      level: '-1',
      map: pBn1,
      backgroundImage: pBn1BG,
    }),
    new Floor({
      level: '0',
      map: pB0,
      backgroundImage: pB0BG,
    }),
    new Floor({
      level: '1',
      map: pB1,
      backgroundImage: pB1BG,
    }),
    new Floor({
      level: '2',
      map: pB2,
      backgroundImage: pB2BG,
    }),
    new Floor({
      level: '3',
      map: pB3,
      backgroundImage: pB3BG,
    }),
  ],
})

const floorsC = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
  windroseRotation: -70,
  list: [
    new Floor({
      level: '0',
      map: pC0,
      backgroundImage: pC0BG,
    }),
    new Floor({
      level: '1',
      map: pC1,
      backgroundImage: pC1BG,
    }),
    new Floor({
      level: '2',
      map: pC2,
      backgroundImage: pC2BG,
    }),
    new Floor({
      level: '3',
      map: pC3,
      backgroundImage: pC3BG,
    }),
  ],
})

const floorsD = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
  windroseRotation: 45,
  list: [
    new Floor({
      level: '0',
      map: pD0,
      backgroundImage: pD0BG,
    }),
    new Floor({
      level: '1',
      map: pD1,
      backgroundImage: pD1BG,
    }),
    new Floor({
      level: '2',
      map: pD2,
      backgroundImage: pD2BG,
    }),
    new Floor({
      level: '3',
      map: pD3,
      backgroundImage: pD3BG,
    }),
  ],
})

const A = new Building({
  name: 'A',
  active: true,
  spritespin: {
    framesURL: {
      day: buildingAImg,
    },
    framesPerRow: 1,
    frames: 1,
    breakpoints: [0],
    maps: [buildingAMap],
    windroseInitialRotation: 20,
  },
  floors: floorsA,
})

const B = new Building({
  name: 'B',
  active: true,
  spritespin: {
    framesURL: {
      day: buildingBImg,
    },
    framesPerRow: 1,
    frames: 1,
    breakpoints: [0],
    maps: [buildingBMap],
    windroseInitialRotation: 20,
  },
  floors: floorsB,
})

const C = new Building({
  name: 'C1',
  active: true,
  spritespin: {
    framesURL: {
      day: buildingCImg,
    },
    framesPerRow: 1,
    frames: 1,
    breakpoints: [0],
    maps: [buildingCMap],
    windroseInitialRotation: -70,
  },
  floors: floorsC,
})

const D = new Building({
  name: 'D',
  active: true,
  spritespin: {
    framesURL: {
      day: buildingDImg,
    },
    framesPerRow: 1,
    frames: 1,
    breakpoints: [0],
    maps: [buildingDMap],
    windroseInitialRotation: 45,
  },
  floors: floorsD,
})

const buildings = new Map()
buildings.set(A.id, A)
buildings.set(B.id, B)
buildings.set(C.id, C)
buildings.set(D.id, D)

const investment = new Investment({
  investmentName: 'Zacisze Strzeszyn',
  buildings,
  buildingsMap,
  buildingsBackground,
})

export default investment

import React from 'react'
import styled from 'styled-components'

import { Heading, Text } from 'components/shared/Typography'
import LazyImage from 'components/shared/LazyImage'
import Container from 'components/shared/Container'
import { Col, Row } from 'components/shared/Grid'

import type Image from 'types/image'

type Props = {
  heading: string
  intro: string
  description: string
  image: Image
}

const Section = styled.section`
  position: relative;
  padding: 4rem 0;
  ${({ theme }) => theme.media.sm.max} {
    padding: 3rem 0 2rem;
    & > ${Container} > span {
      margin: 0 0 0.5rem;
    }
  }
`

const ContentWrapper = styled.article`
  position: relative;
  margin-top: 3rem;
  background: ${({ theme }) => theme.colors.primary};
  min-height: 400px;
  display: flex;
  align-items: center;
  & > ${Row} {
    width: 100%;
    margin: 0;
    & > ${Col} {
      padding: 0;
    }
  }
  ${({ theme }) => theme.media.sm.max} {
    margin-top: 1.5rem;
    margin-left: -1.5rem;
    width: calc(100% + 3rem);
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 250px;
  ${({ theme }) => theme.media.md.min} {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
    width: auto;
    height: auto;
  }
`

const Content = styled.div`
  max-width: 670px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  ${({ theme }) => theme.media.md.min} {
    padding: 5rem 1.5rem;
  }
`

const InvestmentAbout: React.FC<Props> = ({
  heading,
  intro,
  description,
  image,
}) => {
  return (
    <Section id="about">
      <Container slim>
        <Heading
          as="span"
          size={80}
          sizeDiff={0.9}
          weight={500}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Heading
          as="div"
          size={30}
          weight={400}
          sizeDiff={0.6}
          dangerouslySetInnerHTML={{ __html: intro }}
          // transform="uppercase"
        />
        <ContentWrapper>
          <Row alignItems="center">
            <Col xs={24} md={12}>
              <ImageWrapper>
                <LazyImage src={image.src} alt={image.alt ?? ''} fill />
              </ImageWrapper>
            </Col>
            <Col xs={24} md={12}>
              <Content>
                <Text
                  as="div"
                  themecolor="white"
                  size={18}
                  weight={300}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Content>
            </Col>
          </Row>
        </ContentWrapper>
      </Container>
    </Section>
  )
}

export default InvestmentAbout

import React from 'react'
import styled, { css } from 'styled-components'

import { Text } from 'components/shared/Typography'
import Container from 'components/shared/Container'

import convertToRoman from 'utils/convertToRoman'

export type TimelineItem = {
  text: string
  active: boolean
}

type Props = {
  timeline: TimelineItem[]
}

const StyledContainer = styled(Container)`
  padding: 0;
`

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 1rem 0 2rem;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-width: max-content;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 116px;
    left: 70px;
    right: 70px;
    background: ${({ theme }) => theme.colors.primary};
    height: 4px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 116px;
    width: 100vw;
    left: calc(100% - 70px);
    background: ${({ theme }) => theme.colors.white50};
    height: 4px;
    z-index: 1;
  }
`

const TimelineElement = styled.div<{ inactive: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 140px;
  min-width: 140px;
  &:not(:last-child) {
    margin-right: 4rem;
  }
  ${({ theme }) => theme.media.lg.max} {
    max-width: 120px;
    min-width: 120px;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    & > ${Text} {
      font-size: 12px;
    }
  }
  ${({ inactive }) =>
    inactive &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: 70px;
        top: 116px;
        width: 100vw;
        height: 4px;
        background: #dbdbdb;
        z-index: 1;
      }
    `}
`

const Index = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  min-width: 80px;
  min-height: 80px;
`

const Dot = styled.div<{ active: boolean }>`
  position: relative;
  z-index: 2;
  min-width: 26px;
  min-height: 26px;
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  margin: 25px 0;
  transform: rotate(45deg);

  ${({ active }) =>
    active &&
    css`
      border-width: 8px;
    `}
`

const Timeline: React.FC<Props> = ({ timeline }) => {
  const inactiveIndex = timeline.findIndex((el) => !el.active) - 1
  return (
    <StyledContainer slim>
      <Wrapper>
        <InnerWrapper>
          {timeline.map(({ text, active }, index) => (
            <TimelineElement inactive={inactiveIndex === index} key={text}>
              <Index>
                <Text size={26} themecolor={active ? 'black' : 'white100'}>
                  {convertToRoman(index + 1)}
                </Text>
              </Index>
              <Dot active={active} />
              <Text
                size={14}
                weight={500}
                transform="uppercase"
                align="center"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </TimelineElement>
          ))}
        </InnerWrapper>
      </Wrapper>
    </StyledContainer>
  )
}

export default Timeline

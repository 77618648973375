import React, { useEffect } from 'react'
import { MapProps } from 'browser/types'

const Map: React.FC<MapProps> = ({ onLoad }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <polygon
        data-floor="0"
        points="81 714 115 717 416 718 416 720.88 807 726 807 762.32 417 767 417 772 117 781 81 773.58 81 714"
      />
      <polygon
        data-floor="1"
        points="81 714 115 717 416 718 416 720.88 807 726 807 682.46 756.65 682.46 744.51 682.46 494 676 494 669 419 667 384.35 667 385 654 292 650 288 653 128 654 81 654.81 81 714"
      />
      <polygon
        data-floor="2"
        points="81 654.81 128 654 288 653 292 650 385 654 384.35 667 419 667 494 669 494 676 710.77 681.59 807 682.46 807 638.19 756 637 745 638 488 627 488 618 496 614 384 607 384 595 390 590 292 582 285 586 126.25 582 81 586.64 81 654.81"
      />
      <path
        data-floor="3"
        d="M81,586.64S120.5,582,126.25,582,285,586,285,586l7-4,98,8-6,5v12l112,7-8,4v9l257,11,11-1,51,1.19V582l-41-54-92-15,1-11-43-7-8,3v7L359,461V446l-19-3-78-66L81,459.56Z"
        transform="translate(0 0)"
      />
    </svg>
  )
}

export default Map

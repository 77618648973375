import React, { useEffect } from 'react'
import { MapProps } from 'browser/types'

const Map: React.FC<MapProps> = ({ onLoad }) => {
  useEffect(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1066"
    >
      <polygon
        data-floor="0"
        points="637 618 766 601 934 605 968 603 1083 604 1050.76 609.5 1242 614 1283 612 1354 613 1327 616 1492.17 618 1526.54 618 1743.02 618 1743.02 683.19 768 706 637 703.2 637 618"
      />
      <polygon
        data-floor="1"
        points="637 618 766 601 934 605 968 603 1083 604 1050.76 609.5 1242 614 1283 612 1327.05 612.62 1354 613 1327 616 1398.57 616.87 1632.35 618 1743.02 618 1743.02 549.58 1530.3 533 1490 535 1324 523 1347 516 1282 508 1246 514 1057 496 1090 488 967 476 935 484 766 472 637 515.63 637 618"
      />
      <polygon
        data-floor="2"
        points="637 515.63 766 472 935 484 967 476 1090 488 1057 496 1246 514 1282 508 1347 516 1324 523 1490 535 1530.3 533 1743.02 549.58 1746 481 1688.44 472 1646 476 1549 462 1593 456 1532 446 1491 451 1325 427 1370 417 1284 403 1240 413 1056 383 1091 370 966 348 935 363 765 339 634 406 637 515.63"
      />
      <polygon
        data-floor="3"
        points="634 406 765 339 935 363 966 348 1091 370 1056 383 1240 413 1284 403 1370 417 1325 427 1491 451 1532 446 1593 456 1549 462 1646 476 1688.44 472 1746 481 1747 409 1748 409 1748 405 1617 346 1577 334 1504 315 1504 302 1505.89 302 1506 298 1460 286 1432 295 1090 201 1090 185 1092.09 185 1092 180 1010 157 987 173 688 93 634 301.37 634 406"
      />
    </svg>
  )
}

export default Map

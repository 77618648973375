import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'

import { Heading, Text } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import Switch from 'components/shared/Switch'

import Filters from 'components/layout/Filters'
import Browser from 'components/layout/Flat/Browser'
import Browser3D from 'browser'

import useFilters from 'hooks/useFilters'
import useBreakpoint from 'hooks/useBreakpoint'

import injectSmartMakieta from 'browser/utils/injectSmartMakieta'
import getInvestment from 'browser/utils/getInvestment'

import useLocation from 'hooks/useLocation'

import {
  FavouritesColumn,
  TypeColumn,
  NumberColumn,
  AreaColumn,
  FloorColumn,
  RoomsColumn,
  PriceColumn,
  PricePerM2Column,
  StatusColumn,
  GardenSurfaceColumn,
} from 'constants/columns'

import type { Flat } from 'types/flat'
import { Investment } from 'browser/types'

type Props = {
  flats: Flat[]
  garages?: Flat[]
  investment: string
}

const Section = styled.section`
  position: relative;
  overflow: hidden;
  padding: 2rem 0;
  background: ${({ theme }) => theme.colors.white50};
  & > ${Container} {
    position: relative;
    z-index: 1;
  }
`

const FiltersWrapper = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    height: 200vh;
    left: 0;
    right: 0;
    bottom: 50px;
    background: ${({ theme }) => theme.colors.white};
    ${({ theme }) => theme.media.sm.max} {
      bottom: 30px;
    }
  }
`

const AvailableFlatsLabel = styled(Text)`
  text-align: center;

  mark {
    background: transparent;
    font-weight: inherit;
    font-size: inherit;
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme }) => theme.media.lg.min} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const Browser3DWrapper = styled.div`
  margin: 1.5rem 0;
  ${({ theme }) => theme.media.sm.min} {
    margin: 2rem 0 3rem;
  }
`

const SmartMakieta = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
  ${({ theme }) => theme.media.md.max} {
    display: none;
  }
`

const InvestmentBrowser: React.FC<Props> = ({
  flats,
  garages = [],
  investment,
}) => {
  const [smartMakietaInstance, setSmartMakietaInstance] = useState<any>(null)
  const [currentMode, setMode] = useState<'flats' | 'garages'>('flats')

  const location = useLocation()
  const { md } = useBreakpoint()

  const [filteredFlats, { fields, filters, applyFilters, setFilters }] =
    useFilters('flat', {
      items: flats,
      includedFilters: [
        { key: 'type', type: 'select' },
        { key: 'rooms', type: 'select' },
        { key: 'floor', type: 'select' },
        { key: 'area', type: 'range' },
      ],
    })

  const browserInvestment = getInvestment(investment)
  const garageBrowserInvestment = getInvestment(`${investment}-garage`)
  const smartMakieta =
    location === '/art-malta' && getInvestment('art-malta-smart-makieta')

  const isSmartMakieta = typeof smartMakieta === 'string'

  const hasBrowser = !!browserInvestment

  useEffect(() => {
    if (smartMakieta && md && currentMode === 'flats') {
      return injectSmartMakieta(smartMakieta as string, setSmartMakietaInstance)
    }
    return () => null
  }, [browserInvestment, md, currentMode])

  useEffect(() => {
    if (smartMakietaInstance) {
      const flatIDs = filteredFlats.map(({ name }) => name.replace('/', '-'))
      smartMakietaInstance.externalCommunication.setFlatFilters(flatIDs)
    }
  }, [filteredFlats, smartMakietaInstance])

  useEffect(() => {
    if (!isSmartMakieta) {
      const smartMakietaElement = document.getElementById('smart-makieta')
      smartMakietaElement?.remove()
    }
  }, [browserInvestment, md, currentMode, filteredFlats, smartMakietaInstance])

  const flatsAndGaragesSelection = useMemo(
    () => location === '/art-malta' || location === '/zacisze-strzeszyn',
    [location]
  )

  const customRoute = useMemo(
    () =>
      location === '/art-malta' && currentMode === 'garages'
        ? '/building/a/floor/-1'
        : undefined,
    [location, currentMode]
  )

  const availableFlatsNumber = useMemo(
    () => flats.filter((flat) => flat.status === 'available').length,
    [flats]
  )

  const component =
    currentMode === 'flats' ? (
      <SmartMakieta id="smart-makieta" />
    ) : (
      <Browser3D
        investment={browserInvestment as Investment}
        allFlats={[...flats, ...garages]}
        filteredFlats={filteredFlats}
        onFlatEnter={({ link }) => navigate(link)}
        customRoute={customRoute}
      />
    )

  const toggleMode = (passedMode: string) => {
    setMode(passedMode as 'flats' | 'garages')
  }

  return (
    <Section id="flats">
      {hasBrowser && (md || !smartMakieta) && (
        <>
          <Container>
            <Heading
              as="h2"
              align="center"
              size={50}
              sizeDiff={0.7}
              weight={500}
              margin="0"
            >
              {flatsAndGaragesSelection ? 'Wybierz' : 'Wybierz mieszkanie'}
            </Heading>
            {flatsAndGaragesSelection && (
              <Switch
                modes={[
                  {
                    label: 'Mieszkania',
                    value: 'flats',
                    fill: false,
                  },
                  {
                    label: 'Garaże / Komórki lokatorskie',
                    value: 'garages',
                    stroke: false,
                  },
                ]}
                active={currentMode}
                setMode={toggleMode}
                marginTop
                buttonsAbsolute
              />
            )}
          </Container>
          {currentMode === 'garages' && flatsAndGaragesSelection && (
            <Browser3DWrapper>
              {isSmartMakieta ? (
                component
              ) : (
                <Browser3D
                  currentMode={currentMode}
                  investment={garageBrowserInvestment as Investment}
                  allFlats={[...garages]}
                  filteredFlats={filteredFlats}
                  onFlatEnter={({ link }) => navigate(link)}
                  customRoute={customRoute}
                />
              )}
            </Browser3DWrapper>
          )}
          {currentMode === 'flats' && (
            <Browser3DWrapper>
              <SmartMakieta id="smart-makieta" />
              {!isSmartMakieta && (
                <Browser3D
                  currentMode={currentMode}
                  investment={browserInvestment as Investment}
                  allFlats={[...flats]}
                  filteredFlats={filteredFlats}
                  onFlatEnter={({ link }) => navigate(link)}
                  customRoute={customRoute}
                />
              )}
            </Browser3DWrapper>
          )}
        </>
      )}
      <Container>
        <Heading
          as="h2"
          align="center"
          size={50}
          sizeDiff={0.7}
          margin={md ? '10px' : '10px'}
          weight={500}
        >
          Lista mieszkań
        </Heading>
        <AvailableFlatsLabel
          size={md ? 18 : 16}
          weight={400}
          themecolor="darkgray"
          margin={md ? '20px' : '15px'}
          dangerouslySetInnerHTML={{
            __html: `Dostępne mieszkania: <mark>  ${availableFlatsNumber}/${flats.length}  </mark>`,
          }}
        />
      </Container>
      <FiltersWrapper>
        <Filters
          includedFilters={fields}
          filters={filters}
          applyFilters={applyFilters}
          setFilters={setFilters}
        />
      </FiltersWrapper>
      <Browser
        flats={filteredFlats}
        columns={[
          NumberColumn,
          TypeColumn,
          AreaColumn,
          FloorColumn,
          RoomsColumn,
          GardenSurfaceColumn,
          PriceColumn,
          PricePerM2Column,
          StatusColumn,
          FavouritesColumn,
        ]}
      />
    </Section>
  )
}

export default InvestmentBrowser

import { Investment, Building, Floors, Floor } from 'browser/schemes'

import buildingsBackground from './assets/investment.jpg'
import { ReactComponent as buildingsMap } from './maps/investment.svg'

import buildingAMap from './maps/A'
import buildingBMap from './maps/B'

import buildingAImg from './assets/A/building.jpg'
import buildingBImg from './assets/B/building.jpg'

import { ReactComponent as pAn1 } from './maps/A/p-1.svg'
import { ReactComponent as pBn1 } from './maps/B/p-1.svg'

import pAn1BG from './assets/A/p-1.png'
import pBn1BG from './assets/B/p-1.png'

const floorsA = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
  windroseRotation: 20,
  list: [
    new Floor({
      level: '-1',
      map: pAn1,
      backgroundImage: pAn1BG,
    }),
  ],
})

const floorsB = new Floors({
  streets: {
    top: '',
    bottom: '',
    left: '',
    right: '',
  },
  windroseRotation: 20,
  list: [
    new Floor({
      level: '-1',
      map: pBn1,
      backgroundImage: pBn1BG,
    }),
  ],
})

const A = new Building({
  name: 'A',
  active: true,
  spritespin: {
    framesURL: {
      day: buildingAImg,
    },
    framesPerRow: 1,
    frames: 1,
    breakpoints: [0],
    maps: [buildingAMap],
    windroseInitialRotation: 20,
  },
  floors: floorsA,
})

const B = new Building({
  name: 'B',
  active: true,
  spritespin: {
    framesURL: {
      day: buildingBImg,
    },
    framesPerRow: 1,
    frames: 1,
    breakpoints: [0],
    maps: [buildingBMap],
    windroseInitialRotation: 20,
  },
  floors: floorsB,
})

const buildings = new Map()
buildings.set(A.id, A)
buildings.set(B.id, B)

const investment = new Investment({
  investmentName: 'Zacisze Strzeszyn - Garaże',
  buildings,
  buildingsMap,
  buildingsBackground,
})

export default investment
